<template>
  <div class="flex flex-col flex-1">
    <Topbar :pages="pages">
      <button @click="handleDownload()" class="bg-purple-800 text-white px-4 py-2">
        View Exports
      </button>
    </Topbar>
    <div class="flex flex-col p-6 flex-1">
      <div class="border-b pb-2">
        <div class="flex" :key="index" v-for="(filterData, index) in filters">
          <div
              class="w-1/3 cursor-pointer px-4 py-2"
              :key="filter.name"
              v-for="filter in filterData"
          >
            <label :for="filter.name">{{ filter.display_name }}</label>
            <component
                v-bind:is="filter.component"
                :value="filterValues[filter.field]"
                :placeholder="
                `Please Select ${filter.placeholder ? filter.placeholder : filter.display_name}`
              "
                :key="
                filter.value_depend_field
                  ? filterValues[filter.value_depend_field]
                  : `${filter.name}_field`
              "
                :options="getFilterOptions(filter)"
                @selected="value => handleSelectFilterValue(filter, value)"
            >
            </component>
          </div>
        </div>
      </div>
      <div class="flex flex-wrap mb-2 mt-3 border-b pb-2">
        <div
            v-on:click="setFilterMode(status.key)"
            v-bind:key="status.key"
            v-for="status in statuses"
            :class="filter_mode === status.key ? status.class : ''"
            class="flex-grow cursor-pointer px-4 py-2 text-center border-2 mx-2 py-3 "
        >
          {{ status.name }}
        </div>
      </div>
      <div class="flex flex-wrap mb-2 mt-3 border-b pb-2 justify-end">
        <button
            :disabled="exporting"
            @click="handleExportCreate()"
            class="bg-green-800 text-white px-4 py-2"
            :class="{'disabled:opacity-70 cursor-not-allowed': exporting}"
        >
          Create Export
        </button>
        <button
            :disabled="exporting"
            @click="handleResetFilter()"
            class="bg-purple-700 text-white px-4 py-2 ml-2"
            :class="{'disabled:opacity-70 cursor-not-allowed': exporting}"
        >
          Reset Filters
        </button>
      </div>
      <ResourceTable
          ref="resourceTable"
          :disableActions="false"
          name="SurveyArchiveResultTable"
          :columns="columns"
          :page="page"
          :model="SurveyResultArchive"
          :filter="SurveyResultArchiveFilter"
          :query="SurveyResultArchiveQuery"
          :queryKey="listSurveyResultArchivesQueryKey"
          :subscription-gql="SurveyResultArchiveSubscription"
          :variables="SurveyResultArchiveVariables">
        <template v-slot:default="slotProps">
          <button
              type="button"
              @click="handleView(slotProps.item)"
              class="mx-4 px-2 py-2 text-left bg-gray-600 text-white"
          >
            View
          </button>
          <button
              v-if="slotProps.item.mode === 'submitted'"
              type="button"
              :disabled="submitting === slotProps.item.id"
              @click="handleApprove(slotProps.item)"
              class="mx-4 px-2 py-2 text-left bg-green-800 text-white"
          >
            <template v-if="submitting === slotProps.item.id">
              Approving
            </template>
            <template v-if="!submitting || submitting !== slotProps.item.id">
              Approve
            </template>
          </button>
        </template>
      </ResourceTable>
    </div>
  </div>
</template>

<script>
import dayjs from 'dayjs';
import map from 'lodash-es/map';
import find from 'lodash-es/find';
import {filters} from './filters';
import {v4 as uuidv4} from 'uuid';
import chunk from 'lodash-es/chunk';
import moment from 'moment-timezone';
import {useToast} from 'vue-toastification';
import DatePicker from '@/components/DatePicker';
import {DataStore} from '@aws-amplify/datastore';
import {Survey, SurveyResultArchive} from '@/models';
import ResourceTable from '@/components/ResourceTable';
import API, {graphqlOperation} from '@aws-amplify/api';
import {createSurveyExport} from '@/graphql/mutations';
import SelectDropdown from '../../components/SelectDropdown';
import RangeComponent from '../../components/RangeComponent';
import {onUpdateSurveyResultArchive} from '@/graphql/subscriptions';

import {
  getSurveyResult,
  listSurveyResultArchiveByDistrict,
  listSurveyResultArchiveByDivisionalSecretariat,
  listSurveyResultArchiveByGNDivision,
  listSurveyResultArchiveByMode,
  listSurveyResultArchives,
} from '../../graphql/queries';
import {mapGetters} from 'vuex';

export default {
  components: {
    ResourceTable,
    SelectDropdown,
    DatePicker,
    RangeComponent,
  },
  data() {
    return {
      submitting: null,
      exporting: false,
      survey: null,
      value: null,
      filter_mode: '',
      filterValues: {},
      statuses: [
        {
          name: 'All',
          key: '',
          class: 'bg-blue-100',
        },
        {
          name: 'Draft',
          key: 'draft',
          class: 'bg-gray-300',
        },
        {
          name: 'Pending Approval',
          key: 'submitted',
          class: 'bg-gray-300',
        },
        {
          name: 'Approved',
          key: 'completed',
          class: 'bg-gray-300',
        },
        {
          name: 'Pending Processing',
          key: 'ready_for_processing',
          class: 'bg-gray-300',
        },
        {
          name: 'Processed',
          key: 'processed',
          class: 'bg-gray-300',
        },
      ],
      userGroup: null,
    };
  },
  computed: {
    ...mapGetters({
      group: 'app/getGroup',
      groups: 'app/groups',
    }),
    SurveyResultArchiveFilter() {
      return item => item?.survey_id('eq', this.id);
    },
    filters() {
      return chunk(filters, 3);
    },
    SurveyResultArchiveVariables() {
      let variables = {survey_id: this.id};
      let buildFilter = {};
      if (this.filter_mode !== '') {
        buildFilter.mode = {eq: this.filter_mode};
      }
      map(this.filterValues, (value, key) => {
        const currentFilter = find(filters, {name: key});
        if (currentFilter && currentFilter.hidden) {
          return {survey_id: this.id};
        }
        if (currentFilter && currentFilter.type === 'date') {
          if (this.filter_mode !== 'draft') {
            buildFilter[currentFilter.range] = {
              between: [value, this.filterValues[currentFilter.depend]],
            };
          }
          return;
        }


        if (currentFilter && currentFilter.type === 'range') {
          if (value) {
            switch (value.condition) {
              case 'between':
                buildFilter[key] = {between: [parseInt(value.from), parseInt(value.to)]};
                break;
              case '=':
                buildFilter[key] = {eq: parseInt(value.from)};
                break;
              case '<':
                buildFilter[key] = {lt: parseInt(value.from)};
                break;
              case '>':
                buildFilter[key] = {gt: parseInt(value.from)};
                break;
              case '<=':
                buildFilter[key] = {lte: parseInt(value.from)};
                break;
              case '>=':
                buildFilter[key] = {gte: parseInt(value.from)};
                break;
            }
          }
          return;
        }

        if (value) {
          buildFilter[key] = {eq: value};
        }
      });

      if (Object.keys(buildFilter).length) {
        variables = {survey_id: this.id, filter: buildFilter};
      }
      if (this.filterValues.gn_division) {
        variables['gn_divisionCompletedAt'] = {
          between: [
            {
              gn_division: this.filterValues.gn_division,
              completedAt: variables.filter.completedAt?.between[0],
            },
            {
              gn_division: this.filterValues.gn_division,
              completedAt: variables.filter.completedAt?.between[1],
            },
          ],
        };
        return variables;
      } else if (this.filterValues.divisional_secretariat) {
        variables['divisional_secretariatCompletedAt'] = {
          between: [
            {
              divisional_secretariat: this.filterValues.divisional_secretariat,
              completedAt: variables.filter.completedAt?.between[0],
            },
            {
              divisional_secretariat: this.filterValues.divisional_secretariat,
              completedAt: variables.filter.completedAt?.between[1],
            },
          ],
        };
        return variables;
      } else if (this.filterValues.district) {
        variables['districtCompletedAt'] = {
          between: [
            {
              district: this.filterValues.district,
              completedAt: variables.filter.completedAt?.between[0],
            },
            {
              district: this.filterValues.district,
              completedAt: variables.filter.completedAt?.between[1],
            },
          ],
        };
        return variables;
      }
      return variables;
    },
    SurveyResultArchiveQuery() {
      if (this.filterValues.gn_division) {
        return listSurveyResultArchiveByGNDivision;
      } else if (this.filterValues.divisional_secretariat) {
        return listSurveyResultArchiveByDivisionalSecretariat;
      } else if (this.filterValues.district) {
        return listSurveyResultArchiveByDistrict;
      } else if (this.filter_mode !== '') {
        return listSurveyResultArchiveByMode;
      }
      return listSurveyResultArchives;
    },
    listSurveyResultArchivesQueryKey() {
      if (this.filterValues.gn_division) {
        return 'listSurveyResultArchiveByGNDivision';
      } else if (this.filterValues.divisional_secretariat) {
        return 'listSurveyResultArchiveByDivisionalSecretariat';
      } else if (this.filterValues.district) {
        return 'listSurveyResultArchiveByDistrict';
      } else if (this.filter_mode !== '') {
        return 'listSurveyResultArchiveByMode';
      }
      return 'listSurveyResultArchives';
    },
    SurveyResultArchiveSubscription() {
      return onUpdateSurveyResultArchive;
    },
    SurveyResultArchive() {
      return SurveyResultArchive;
    },
    id() {
      return this.$route.params.id;
    },
    page() {
      return parseInt(this.$route.query.page, 10);
    },
    columns() {
      return [
        {
          name: 'district',
          text: 'District',
          render: item => item?.district,
        },
        {
          name: 'divisional_secretariat',
          text: 'Divisional Secretariat',
          render: item => item?.divisional_secretariat,
        },
        {
          name: 'mode',
          text: 'Status',
          render: item => item?.mode,
        },
        {
          name: 'completedAt',
          text: 'Completed',
          render: item =>
              item?.mode === 'processed' ? dayjs(item?.completedAt).format('YYYY-MM-DD') : '',
        },
        {
          name: 'createdAt',
          text: 'Created',
          render: item => dayjs(item?.createdAt).format('YYYY-MM-DD'),
        },
      ];
    },
    pages() {
      return [
        {
          name: 'Surveys',
          to: {name: 'surveys'},
          current: false,
        },
        {
          name: this.survey ? this.survey.survey_name : 'Surveys View',
          to: {name: 'surveys.results', params: {id: this.id}},
          current: false,
        },
      ];
    },
  },
  async created() {
    this.userGroup = await this.group;
  },
  methods: {
    handleSelectFilterValue(filter, value) {
      this.filterValues[filter.field] = value;
    },
    handleGetBaseFilters() {
      if (!this.userGroup) {
        return null;
      }
      if (this.userGroup.includes('Admin') || this.userGroup.includes('Super')) {
        return null;
      }
      this.userGroup.map(group => {
        if (group.includes('district:')) {
          this.setFilterValue(group, this.groups.district, 'district');
        }
        if (group.includes('ds:')) {
          const divisional = this.setFilterValue(group, this.groups.ds, 'divisional_secretariat');
          if (divisional) {
            this.setFilterValue(divisional.district, this.groups.district, 'district', 'name');
          }
        }

        if (group.includes('gn:')) {
          const gn = this.setFilterValue(group, this.groups.gn, 'gn_division');
          if (gn) {
            const divisional = this.setFilterValue(
                gn.ds,
                this.groups.ds,
                'divisional_secretariat',
                'name',
            );
            if (divisional) {
              this.setFilterValue(divisional.district, this.groups.district, 'district', 'name');
            }
          }
        }
      });
    },
    setFilterValue(group, data, key, filterKey = 'value') {
      const item = data.filter(item => item[filterKey] === group)[0];
      if (item) {
        this.filterValues[key] = item.name;
      }
      return item;
    },
    getFilterOptions(filter) {
      this.handleGetBaseFilters();
      const mainData = find(filter.values, {key: 'data'});
      if (mainData && mainData.values) {
        return mainData.values;
      }
      if (filter.value_depend_field) {
        const dependField = this.filterValues[filter.value_depend_field];
        if (dependField) {
          const data = find(filter.values, {key: dependField});
          if (data && data.values) {
            return data.values;
          }
        } else {
          const values = filter.values;
          const deduced = values.map(value => value.values);
          const deducedIter = [];
          deduced.forEach(item => {
            item.map(value => {
              deducedIter.push(value);
            });
          });
          return deducedIter;
        }
      }
      return [];
    },
    setFilterMode(mode) {
      this.filter_mode = mode;
    },
    async handleApprove(item) {
      this.submitting = item.id;
      const data = await API.graphql(
          graphqlOperation(getSurveyResult, {
            id: item.survey_result_id,
          }),
      );
      const surveyResult = data.data.getSurveyResult;
      const customInput = {
        id: item.survey_result_id,
        mode: 'completed',
        _version: surveyResult._version,
      };
      const updateSurveyResult = `mutation updateSurveyResult($input: UpdateSurveyResultInput!){
        updateSurveyResult(input: $input) {
            id
            mode
            survey_result
            survey_detail
            _version
            _lastChangedAt
        }
    }`;
      await API.graphql(graphqlOperation(updateSurveyResult, {input: customInput}));
    },
    async loadSurvey() {
      this.survey = await DataStore.query(Survey, this.id);
    },
    handleView(item) {
      this.$router.push({
        name: 'surveys.view',
        params: {id: this.id, result: item.id},
      });
    },
    handleDownload() {
      this.$router.push({
        name: 'surveys.downloads',
        params: {id: this.id},
      });
    },
    async handleExportCreate() {
      this.exporting = true;
      let values = {
        survey_id: this.id,
        export_id: uuidv4(),
        status: 'pending',
        filters: JSON.stringify(this.SurveyResultArchiveVariables.filter),
        initial_date: moment(new Date(this.filterValues.start_date)).format('YYYY-MM-DD'),
        last_date: moment(new Date(this.filterValues.end_date)).format('YYYY-MM-DD'),
        exported_at: new Date(),
      };
      delete values['custom_filter_index'];
      delete values['custom_filter_field'];
      delete values['custom_filter_value'];

      if (this.filterValues.gn_division !== undefined && this.filterValues.gn_division !== null) {
        values['custom_filter_index'] = 'byGSDivisionCompletedAt';
        values['custom_filter_field'] = 'gn_division';
        values['custom_filter_value'] = this.filterValues.gn_division;
      } else if (
          this.filterValues.divisional_secretariat !== undefined &&
          this.filterValues.divisional_secretariat
      ) {
        values['custom_filter_index'] = 'byDivisionalSecretariatCompletedAt';
        values['custom_filter_field'] = 'divisional_secretariat';
        values['custom_filter_value'] = this.filterValues.divisional_secretariat;
      } else if (this.filterValues.district !== undefined && this.filterValues.district) {
        values['custom_filter_index'] = 'byDistrictCompletedAt';
        values['custom_filter_field'] = 'district';
        values['custom_filter_value'] = this.filterValues.district;
      }
      const toast = useToast();
      await API.graphql(
          graphqlOperation(createSurveyExport, {
            input: values,
          }),
      )
          .then(() => {
            toast.success('Survey Export Successfully Initiated.', {
              timeout: 5000,
            });
            this.exporting = false;
          })
          .catch(() => {
            toast.success('Survey Export Failed.', {
              timeout: 5000,
            });
            this.exporting = false;
          });
    },
    getComponent() {
      return 'SelectDropdown';
    },
    handleResetFilter() {
      this.filterValues = {
        start_date: new Date(),
        end_date: new Date(),
      };
      this.filter_mode = '';
    },
  },
};
</script>

<style src="vue-multiselect/dist/dist/vue-multiselect.css"></style>
