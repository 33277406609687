<template>
  <div class="w-full">
    <div class="flex flex-1 my-2">
      <div>
        <SelectDropdown :options="options" @selected="handleDDChange" :value="value.condition" />
      </div>
      <div class="my-2 ml-2">
        <input
          class="py-2 px-3 field w-full border"
          type="number"
          :placeholder="`${value.condition === 'between' ? 'from' : 'value'}`"
          min="0"
          name="from"
          :value="value.from"
          @keypress="handleInputChange($event, 'from')"
          @blur="handleInputChange($event, 'from')"
        />
      </div>
      <div class="my-2 ml-2" v-if="value.condition === 'between'">
        <input
          :key="`to_${value.from ? value.from : 0}`"
          class="py-2 px-3 field w-full border"
          type="number"
          :min="value.from ? value.from : 0"
          :placeholder="`${value.condition === 'between' ? 'to' : 'value'}`"
          name="to"
          @keypress="handleInputChange($event, 'to')"
          @blur="handleInputChange($event, 'to')"
          :value="value.to"
        />
      </div>
    </div>
  </div>
</template>

<script>
import SelectDropdown from './SelectDropdown';
export default {
  name: 'RangeComponent',
  template: 'RangeComponent',
  components: {
    SelectDropdown,
  },
  props: {
    name: {
      type: String,
      required: false,
      default: 'dropdown',
      note: 'Input name',
    },
    placeholder: {
      type: String,
      required: false,
      default: 'Please select an option',
      note: 'Placeholder of dropdown',
    },
    value: {
      type: Object,
      required: false,
      default: () => {
        return {
          from: 0,
          to: 0,
          condition: null,
        };
      },
    },
    disabled: {
      type: Boolean,
    },
  },
  computed: {},
  data() {
    return {
      options: ['=', '>', '<', '>=', '<=', 'between'],
    };
  },
  methods: {
    handleDDChange(selected) {
      console.log(selected, 'selected');
      this.$emit('selected', {
        ...this.value,
        condition: selected,
      });
    },
    handleInputChange($event, name) {
      this.$emit('selected', {
        ...this.value,
        [name]: $event.target.value,
      });
    },
  },
  watch: {
    // eslint-disable-next-line vue/no-arrow-functions-in-watch
    value: {
      immediate: true,
      handler() {},
    },
  },
};
</script>
