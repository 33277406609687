<template>
  <div class="w-full">
    <Datepicker class="border-2 p-3 py-2" v-model="date"></Datepicker>
  </div>
</template>

<script>
import Datepicker from 'vue3-datepicker';

export default {
  name: 'DatePicker',
  template: 'DatePicker',
  components: {
    Datepicker,
  },
  props: {
    name: {
      type: String,
      required: false,
      default: 'dropdown',
      note: 'Input name',
    },
    value: {
      type: Object,
      required: false,
      note: 'Max items showing',
    },
    disabled: {
      type: Boolean,
    },
  },
  computed: {},
  data() {
    return {
      filter: null,
      date: new Date(),
    };
  },
  watch: {
    // eslint-disable-next-line vue/no-arrow-functions-in-watch
    date: {
      immediate: true,
      handler(current) {
        this.$emit('selected', current);
      },
    },
  },
};
</script>

<style>
:root {
  --vdp-hover-bg-color: #5f1ab1;
  --vdp-selected-bg-color: #5f1ab1;
}
</style>
